import { ApolloError } from '@apollo/client/errors';
import { logger } from './logger';

type validationErrorHandlerType = {
  code: string;
  message: string;
}[];

// this validation error handler only works for errors coming from the nest api
// hasura sends different codes
export const validationErrorHandler = (apolloError: ApolloError) => {
  const errorArray: validationErrorHandlerType = [];

  if (apolloError?.graphQLErrors) {
    logger.log('graphQLErrors', apolloError?.graphQLErrors);

    apolloError.graphQLErrors
      .filter(
        (error) => error?.extensions?.code === 'GRAPHQL_VALIDATION_FAILED',
      )
      .forEach((error) => {
        errorArray.push({
          code: String(error?.extensions?.code) || 'unknown',
          message: error.message || 'Something went wrong',
        });
      });
  }

  return errorArray;
};
